<template>
  <b-card
    no-body
    class="pa-0"
  >
    <div class="d-flex justify-content-end m-1">
      <b-button
        v-if="!loader"
        variant="primary"
        class="ma-1"
        @click="updateCategory"
      >
        <span class="text-nowrap"> Update Category </span>
      </b-button>
      <b-button
        v-if="loader"
        variant="primary"
        disabled
        class="mr-1"
      >
        <b-spinner
          small
          type="grow"
        />
        Loading...
      </b-button>
    </div>

    <general-table
      ref="damenCategory"
      :api-url="APIURL"
      :columns="columns"
      :type="type"
      :view-content="viewContent"
      :delete-content="false"
      :selectable="false"
      :export-table="false"
      guard="merchant_types"
    >
      <template #cell(name)="data">
        {{ data.item.name_ar }}
      </template>
      <template #cell(image)="data">

        <img
          v-if="data.item.image"
          :src="url+data.item.image"
          width="80"
        >
        <span v-else>   <feather-icon
          icon="ImageIcon"
          size="18"
        />
        </span>
      </template>
      <template #cell(created_at)="data">
        {{ timeFormat(data.item.created_at) }}
      </template>
    </general-table>
    <div>   <b-modal
      id="damenCategory"
      ref="genmodal"
      hide-footer
      centered
      no-close-on-backdrop
      title="Edit Category"
    ><edit-category />
    </b-modal></div>
  </b-card>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import EditCategory from './control-category-aggregators/EditCategoryAggregators.vue'

export default {
  components: { GeneralTable, EditCategory },
  data() {
    return {
      APIURL: 'categoryAggregators',
      type: 'modal',
      url: 'https://v2.zerocash.co/storage/',
      viewContent: false,
      loader: false,
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'image', label: 'Image' },
        { key: 'created_at', label: 'Created At' },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },

  methods: {
    timeFormat(date) {
      return moment(date).format(' DD MMM YYYY')
    },
    updateCategory() {
      this.loader = true
      axios.post('category_aggregators/update').then(res => {
        if (res.status === 200 || res.status === 201) {
          this.loader = false
          this.$refs.damenCategory.getAllData()
          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        } else {
          this.loader = false
          this.$toasted.show('Server Error', {
            position: 'top-center',
            duration: 3000,
            variant: 'danger',
          })
        }
      })
    },
  },

}
</script>

<style>

</style>
